// src/pages/CookiePolicyPage.js
import React from "react";
import NotFoundPage from "../notFoundPage";
import { connect } from "react-redux";

const JobPosting = ({ activeLang }) => {
  // Static content for the cookie policy
  const content = {
    tr: {
      title: "Bilgi Güvenliği ve Kalite Politikası",
      description: `
                <div>
    <section>
        <h2>Bilgi Güvenliği Politikamız</h2>
        <p>
            KURULUŞUMUZ; bünyesinde bulunan bilgi güvenliği yönetim sisteminin sürekli gelişimini sağlamak için bilgi varlıklarını tanımlamayı ve bu varlıkların;
        </p>
        <ul>
            <li>
                <strong>İş Etkisini:</strong> Varlığı yerine koyma maliyeti, bilginin gizliliği, imaja olan etkisi, yasal ve hukuki yükümlülükler bakımından yaratacağı zarara gibi konuları ele alarak,
            </li>
            <li>
                <strong>Tehdit Olasılığını:</strong> Zayıflıkların çokluğu ve var olan kontrollerin bu zayıflıkları ne kadar kapatabildiği, saldırgan motivasyonu, bilginin rakipler için cazibesi, erişim kontrollerindeki açıklar ve bilginin bütünlüğüne ilişkin tehlikeleri ele alarak,
            </li>
        </ul>
        <p>
            Bilginin gizliliği, bütünlüğü ve erişilebilirliğine ilişkin riskleri ve fırsatları belirlemeyi ve değerlendirmeyi,
        </p>
        <ul>
            <li>Kabul edilebilir risk seviyesinin üzerinde bulunan tüm varlıklar için gerekli kontrolleri uygulamayı,</li>
            <li>Bilgi güvenliği ve gizlilik süreçlerinin performansını ölçmeyi,</li>
            <li>Bu verilerden BGYS ve KVKK kapsamında amaçlar ve hedefler üretmeyi,</li>
            <li>Olası zayıflık ve tehditleri altyapı, çalışma ortamı, donanım, yazılım ve eğitim yatırımlarıyla en aza indirgemeyi,</li>
            <li>İşimizin, müşterilerimizin ve yasal şartların gerektirdiği güvenlik şartlarını karşılamayı,
            </li>
        </ul>
        <p><strong>TAAHHÜT EDERİZ.</strong></p>
    </section>
    <br><br>
    <br><br>
    <section>
        <h2>Kalite Politikamız</h2>
        <ul>
            <li>Müşteri beklentisinin ötesine geçmeyi hedefleyerek ekip çalışmasını desteklemek,</li>
            <li>Maksimum müşteri memnuniyeti sağlamak,</li>
            <li>Müşteri ile etkin iletişim sağlamak,</li>
            <li>Tedarikçilerle güvene dayalı bir ilişki oluşturarak tedarik ürünlerini en ekonomik, en kaliteli ve en kısa sürede temin etmek,</li>
            <li>Bütün çalışanlar için düzenli eğitimler organize etmek ve tam katılımı sağlamak,</li>
            <li>Çalışanların motivasyonunu artırmak için gerekli yöntemleri araştırmak ve yönetim desteği ile bunları gerçekleştirmek,</li>
            <li>Problemlerin çözümünde bireysel karar değil ekip çalışması odaklı olmak,</li>
            <li>Hata kaynaklarını ortadan kaldırmaya yönelik bir strateji belirlemek ve sonucunda daha kaliteli ve verimli bir üretime ulaşmak,</li>
            <li>Yeni teknolojik gelişmeleri takip etmek ve firmalar bünyesinde uygulanabilirliklerini araştırmak,</li>
            <li>Tüm çalışanlar için performans değerlendirmesi yapmak ve sonuçlar doğrultusunda verimlilik çalışmaları yapmak,</li>
        </ul>
    </section>
</div>
            `,
    },
    en: {
      title: "Information Security and Quality Policy",
      description: `
                <div>
    <section>
        <h2>Bilgi Güvenliği Politikamız</h2>
        <p>
            KURULUŞUMUZ; bünyesinde bulunan bilgi güvenliği yönetim sisteminin sürekli gelişimini sağlamak için bilgi varlıklarını tanımlamayı ve bu varlıkların;
        </p>
        <ul>
            <li>
                <strong>İş Etkisini:</strong> Varlığı yerine koyma maliyeti, bilginin gizliliği, imaja olan etkisi, yasal ve hukuki yükümlülükler bakımından yaratacağı zarara gibi konuları ele alarak,
            </li>
            <li>
                <strong>Tehdit Olasılığını:</strong> Zayıflıkların çokluğu ve var olan kontrollerin bu zayıflıkları ne kadar kapatabildiği, saldırgan motivasyonu, bilginin rakipler için cazibesi, erişim kontrollerindeki açıklar ve bilginin bütünlüğüne ilişkin tehlikeleri ele alarak,
            </li>
        </ul>
        <p>
            Bilginin gizliliği, bütünlüğü ve erişilebilirliğine ilişkin riskleri ve fırsatları belirlemeyi ve değerlendirmeyi,
        </p>
        <ul>
            <li>Kabul edilebilir risk seviyesinin üzerinde bulunan tüm varlıklar için gerekli kontrolleri uygulamayı,</li>
            <li>Bilgi güvenliği ve gizlilik süreçlerinin performansını ölçmeyi,</li>
            <li>Bu verilerden BGYS ve KVKK kapsamında amaçlar ve hedefler üretmeyi,</li>
            <li>Olası zayıflık ve tehditleri altyapı, çalışma ortamı, donanım, yazılım ve eğitim yatırımlarıyla en aza indirgemeyi,</li>
            <li>İşimizin, müşterilerimizin ve yasal şartların gerektirdiği güvenlik şartlarını karşılamayı,
            </li>
        </ul>
        <p><strong>TAAHHÜT EDERİZ.</strong></p>
    </section>

    <section>
        <h2>Kalite Politikamız</h2>
        <ul>
            <li>Müşteri beklentisinin ötesine geçmeyi hedefleyerek ekip çalışmasını desteklemek,</li>
            <li>Maksimum müşteri memnuniyeti sağlamak,</li>
            <li>Müşteri ile etkin iletişim sağlamak,</li>
            <li>Tedarikçilerle güvene dayalı bir ilişki oluşturarak tedarik ürünlerini en ekonomik, en kaliteli ve en kısa sürede temin etmek,</li>
            <li>Bütün çalışanlar için düzenli eğitimler organize etmek ve tam katılımı sağlamak,</li>
            <li>Çalışanların motivasyonunu artırmak için gerekli yöntemleri araştırmak ve yönetim desteği ile bunları gerçekleştirmek,</li>
            <li>Problemlerin çözümünde bireysel karar değil ekip çalışması odaklı olmak,</li>
            <li>Hata kaynaklarını ortadan kaldırmaya yönelik bir strateji belirlemek ve sonucunda daha kaliteli ve verimli bir üretime ulaşmak,</li>
            <li>Yeni teknolojik gelişmeleri takip etmek ve firmalar bünyesinde uygulanabilirliklerini araştırmak,</li>
            <li>Tüm çalışanlar için performans değerlendirmesi yapmak ve sonuçlar doğrultusunda verimlilik çalışmaları yapmak,</li>
        </ul>
    </section>
</div>
            `,
    },
  };

  // Aktif dile göre içerik seçimi

  const pageContent = content[activeLang];
  const styles = {
    cookiePolicyBlock: {
      maxWidth: "90%",
      margin: "100px auto",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      fontFamily: "Montserrat-Regular",
    },
    title: {
      fontSize: "26px", // Updated to 26px
      fontWeight: 400, // Set font weight to 400
      color: "#333",
      marginBottom: "15px",
      textAlign: "center",
      fontFamily: "Montserrat-Regular",
    },
    subtitle: {
      fontSize: "20px", // Set to 20px
      fontWeight: 400, // Set font weight to 400
      color: "#444",
      marginTop: "20px",
      marginBottom: "10px",
      fontFamily: "Montserrat-Regular",
    },
    list: {
      listStyleType: "disc",
      paddingLeft: "20px",
      fontWeight: 400, // Set font weight to 400
      fontFamily: "Montserrat-Regular",
    },
    listItem: {
      marginBottom: "10px",
      lineHeight: "1.6",
      fontWeight: 400, // Set font weight to 400
      fontFamily: "Montserrat-Regular",
    },
    textContent: {
      fontSize: "20px", // Set to 20px
      fontWeight: 400, // Set font weight to 400
      color: "#555",
      lineHeight: "1.6",
      fontFamily: "Montserrat-Regular",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginTop: "20px",
      fontSize: "20px", // Set to 20px
      overflowX: "auto",
      fontFamily: "Montserrat-Regular",
    },
    th: {
      backgroundColor: "#eee",
      padding: "10px",
      textAlign: "left",
      fontWeight: 400, // Set font weight to 400
      fontFamily: "Montserrat-Regular",
    },
    td: {
      padding: "10px",
      border: "1px solid #ddd",
      fontWeight: 400, // Set font weight to 400
      fontFamily: "Montserrat-Regular",
    },
  };
  return (
    <div>
      {pageContent ? (
        <div style={styles.cookiePolicyBlock}>
          <h3 style={styles.title}>{pageContent.title}</h3>

          <div
            className="text-item"
            style={styles.textContent}
            dangerouslySetInnerHTML={{ __html: pageContent.description }}
          />
        </div>
      ) : (
        <NotFoundPage {...this.props} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeLang: state.langReducer.activeLang,
});

export default connect(mapStateToProps)(JobPosting);
